$(document).ready(function () {
  $(".table__price td").hover(function () {
    $el = $(this);
    $el.parent().addClass("hover");
    if ($el.parent().has('td[rowspan]').length == 0)
      $el
        .parent()
        .prevAll('tr:has(td[rowspan]):first')
        .find('td[rowspan]')
        .addClass("hover");
  }, function () {
    $el
      .parent()
      .removeClass("hover")
      .prevAll('tr:has(td[rowspan]):first')
      .find('td[rowspan]')
      .removeClass("hover");
  });
});

$(document).ready(function () {
  $('.year').html(new Date().getFullYear());
});

$(document).ready(function () {

  /*анимация лебла в заявке*/

  let item = $('.form__item');

  if (item.find('.inputtext').val().length >= 1) {
    $(this).find('.form__item-label').addClass('active');
  }

  item.focusin(function () {
    $(this).find('.form__item-label').addClass('active');
  });

  item.focusout(function () {
    if (item.find('.inputtext').val().length <= 1) {
      $(this).find('.form__item-label').removeClass('active');
    }
  });

  /*mainform*/

  let item1 = $('form[name=SIMPLE_FORM_2] .form__item');
  item1.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_3]').val().length > 1 && $('input[name=form_text_40]').val().length > 1) {
      $('.form__item.captcha').addClass('active');
    } else {
      $('.form__item.captcha').removeClass('active');
    }
  });

  /*mainform 2*/
  let item2 = $('form[name=SIMPLE_FORM_9] .form__item');
  item2.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_47]').val().length > 1 && $('input[name=form_text_51]').val().length > 1) {
      $('form[name=SIMPLE_FORM_9] .form__item.captcha').addClass('active');
    } else {
      $('form[name=SIMPLE_FORM_9] .form__item.captcha').removeClass('active');
    }
  });

  /*modal__request*/
  let modalItem1 = $('form[name=SIMPLE_FORM_3] .form-ask__item');

  modalItem1.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_7]').val().length > 1 && $('input[name=form_text_8]').val().length > 1 && $('input[name=form_email_15]').val().length > 1) {
      $('form[name=SIMPLE_FORM_3] .form-ask__item.captcha').addClass('active');
    } else {
      $('form[name=SIMPLE_FORM_3] .form-ask__item.captcha').removeClass('active');
    }
  });

  /*modal__buy*/
  let modalItem2 = $('form[name=SIMPLE_FORM_4] .form-ask__item');
  modalItem2.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_17]').val().length > 1 && $('input[name=form_text_18]').val().length > 1) {
      $('form[name=SIMPLE_FORM_4] .form-ask__item.captcha').addClass('active');
    } else {
      $('form[name=SIMPLE_FORM_4] .form-ask__item.captcha').removeClass('active');
    }
  });
});

$(document).ready(function () {
  $('.page__content-item-image img').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).toggleClass('active');
    } else {
      $('.page__content-item-image img').removeClass('active');
      $(this).addClass('active');
    }
  })
});

$(document).ready(function () {
  $('.header__toggle').on('click', function () {
    $('.toggle__menu').toggleClass('active');
  });

  $('.close-button').on('click', function () {
    $('.toggle__menu').toggleClass('active');
  });
});

$(document).ready(function () {
  if ($(window).width() >= 425 && $(window).width() <= 1200) {
    $('.front-page-gallery .container .news-list').masonry({
      // options
      itemSelector: '.news-item',
      columnWidth: '.grid-sizer',
      gutter: '.gutter-sizer',
    });
  } else if ($(window).width() >= 1200) {
    $('.front-page-gallery .container .news-list').masonry({
      // options
      itemSelector: '.news-item',
      columnWidth: '.grid-sizer',
      gutter: '.gutter-sizer',
      horizontalOrder: true,
    });
  }
});

$(document).ready(function () {
  $('.page__sizes-links-item').on('click', function (e) {
    e.preventDefault();
    $('.page__sizes-links .cancel').addClass('active');
    let num = $(this).attr('data-link');
    if ($(this).hasClass('active')) {
      $(this).toggleClass('active');
      $('.page__content').find('.page__content-item[id=' + num + ']').toggleClass('active');
    } else {
      $(this).toggleClass('active');
      $('.page__content-item:not(.active)').addClass('hidden');
      $('.page__content').find('.page__content-item[id=' + num + ']').addClass('active');
    }
  });

  $('.page__sizes-links .cancel').on('click', function (e) {
    $(this).removeClass('active');
    $('.page__sizes-links-item').removeClass('active');
    $('.page__content-item').removeClass('active')
      .removeClass('hidden');
    if ($(window).width() <= 425) {
      $('.page__sizes-links-row').toggleClass('active');
    }
  })
});

$(document).ready(function () {
  $('.price-page__links-item').on('click', function (e) {
    e.preventDefault();
    let num = $(this).attr('data-link');
    if ($(this).hasClass('active')) {
      $(this).toggleClass('active');
      $('.table__wrapper').addClass('hidden');
      $('.table__wrapper[data-link=' + num + ']').toggleClass('active');
    } else {
      $('.price-page__links-item.all').removeClass('active');
      $(this).toggleClass('active');
      $('.table__wrapper[data-link=' + num + ']').addClass('active');
      $('.table__wrapper:not(.active)').addClass('hidden');
    }
  });

  $('.price-page__links-item.all').addClass('active')
    .on('click', function (e) {
      e.preventDefault();
      $('.price-page__links-item').removeClass('active');
      $(this).addClass('active');
      $('.table__wrapper').removeClass('active')
        .removeClass('hidden');
    })
});

$(document).ready(function () {
  $('.gallery-page__links-item').on('click', function (e) {
    e.preventDefault();
    let num = $(this).attr('data-link');
    if ($(this).hasClass('active')) {
      $(this).toggleClass('active');
      $('.gallery-page__list-item').addClass('hidden');
      $('.gallery-page__list-item[data-link=' + num + ']').toggleClass('active');
    } else {
      $('.gallery-page__links-item.all').removeClass('active');
      $(this).toggleClass('active');
      $('.gallery-page__list-item[data-link=' + num + ']').addClass('active');
      $('.gallery-page__list-item:not(.active)').addClass('hidden');
    }
  });

  $('.gallery-page__links-item.all').on('click', function (e) {
    e.preventDefault();
    $('.gallery-page__links-item').removeClass('active');
    $(this).addClass('active');
    $('.gallery-page__list-item').removeClass('active')
      .removeClass('hidden');
  })
});

$(document).ready(function () {
  $('.page__content-item-footer-link .button').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__buy').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.modal__buy form .close').on('click', function () {
    $('.modals__wrapper').fadeOut();
    $('.modals__wrapper .modal__buy').fadeOut();
    $('body').css('overflow', 'unset');
  });

  $('.page__sizes-button-item').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__request').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.front-page-banner__btn').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__request').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.page__subtitle-button-item').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__request').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.modal__request form .close').on('click', function () {
    $('.modals__wrapper').fadeOut();
    $('.modals__wrapper .modal__request').fadeOut();
    $('body').css('overflow', 'unset');
  });
});

BX.addCustomEvent('onAjaxSuccess', function () {
  $('.page__content-item-footer-link .button').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__buy').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.modal__buy form .close').on('click', function () {
    $('.modals__wrapper').fadeOut();
    $('.modals__wrapper .modal__buy').fadeOut();
    $('body').css('overflow', 'unset');
  });

  $('.page__sizes-button-item').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__request').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.front-page-banner__btn').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__request').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.page__subtitle-button-item').on('click', function () {
    $('.modals__wrapper').fadeIn(100);
    $('.modals__wrapper .modal__request').fadeIn(250);
    $('body').css('overflow', 'hidden');
  });

  $('.modal__request form .close').on('click', function () {
    $('.modals__wrapper').fadeOut();
    $('.modals__wrapper .modal__request').fadeOut();
    $('body').css('overflow', 'unset');
  });
});

$(document).ready(function () {
  $('.news-detail-wrapper').magnificPopup({
    delegate: '.news-detail__item', // child items selector, by clicking on it popup will open
    type: 'image',
    gallery: {enabled: true}
    // other options
  });
});


$(document).ready(function () {
  $('.page__sizes-links-title').on('click', function () {
    $('.page__sizes-links-row').toggleClass('active');
    if ($('.page__sizes-links-row').hasClass('active')) {
      $(this).html('Скрыть список');
    } else {
      $(this).html('Размеры ангаров:');
    }
  });

  $('.gallery-page__links-title').on('click', function () {
    $('.gallery-page__links').toggleClass('active');
    if ($('.gallery-page__links').hasClass('active')) {
      $(this).html('Скрыть список');
    } else {
      $(this).html('Выбрать проект:');
    }
  });

  $('.price-page__links-title').on('click', function () {
    $('.price-page__links-row').toggleClass('active');
    if ($('.price-page__links-row').hasClass('active')) {
      $(this).html('Скрыть список');
    } else {
      $(this).html('Выбрать ассортимент:');
    }
  });
});

BX.addCustomEvent('onAjaxSuccess', function () {
  /*гребаный аякс битрикса))))) простой дубль документ реади, на большее пока котелка не хватает*/
  let itemName = $('.form__item.name');
  if (itemName.find('.inputtext').val().length >= 1) {
    itemName.find('.form__item-label').addClass('active');
  }

  let itemPhone = $('.form__item.phone');
  if (itemPhone.find('.inputtext').val().length >= 1) {
    itemPhone.find('.form__item-label').addClass('active');
  }

  let itemEmail = $('.form__item.email');
  if (itemEmail.find('.inputtext').val().length >= 1) {
    itemEmail.find('.form__item-label').addClass('active');
  }

  let item = $('.form__item');

  if (item.find('.inputtext').val().length >= 1) {
    $(this).find('.form__item-label').addClass('active');
  }

  item.focusin(function () {
    $(this).find('.form__item-label').addClass('active');
  });

  item.focusout(function () {
    if (item.find('.inputtext').val().length <= 1) {
      $(this).find('.form__item-label').removeClass('active');
    }
  });

  /*mainform*/

  let item1 = $('form[name=SIMPLE_FORM_2] .form__item');
  item1.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_3]').val().length > 1 && $('input[name=form_text_40]').val().length > 1) {
      $('.form__item.captcha').addClass('active');
    } else {
      $('.form__item.captcha').removeClass('active');
    }
  });

  /*mainform 2*/
  let item2 = $('form[name=SIMPLE_FORM_9] .form__item');
  item2.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_47]').val().length > 1 && $('input[name=form_text_51]').val().length > 1) {
      $('form[name=SIMPLE_FORM_9] .form__item.captcha').addClass('active');
    } else {
      $('form[name=SIMPLE_FORM_9] .form__item.captcha').removeClass('active');
    }
  });

  /*modal__request*/
  let modalItem1 = $('form[name=SIMPLE_FORM_3] .form-ask__item');

  modalItem1.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_7]').val().length > 1 && $('input[name=form_text_8]').val().length > 1 && $('input[name=form_email_15]').val().length > 1) {
      $('form[name=SIMPLE_FORM_3] .form-ask__item.captcha').addClass('active');
    } else {
      $('form[name=SIMPLE_FORM_3] .form-ask__item.captcha').removeClass('active');
    }
  });

  /*modal__buy*/
  let modalItem2 = $('form[name=SIMPLE_FORM_4] .form-ask__item');
  modalItem2.find('.inputtext').on('keyup', function () {
    if ($('input[name=form_text_17]').val().length > 1 && $('input[name=form_text_18]').val().length > 1) {
      $('form[name=SIMPLE_FORM_4] .form-ask__item.captcha').addClass('active');
    } else {
      $('form[name=SIMPLE_FORM_4] .form-ask__item.captcha').removeClass('active');
    }
  });
});